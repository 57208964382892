import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import axiosManager from './services/axiosManager'

// **** GLOBAL VARS ****
const apiService = new axiosManager();

const app = createApp(App);
app.use(router);
app.provide('apiService', apiService);
app.mount('#app');