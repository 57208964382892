import { apiBaseUrl } from "@/config/config";
import axios from "axios";

export default class axiosManager {
    baseUrl;


    // **************************************************
    constructor() {
        this.baseUrl = apiBaseUrl;
    }


    // **************************************************
    // Retrieve the list of categories and audio
    async getList(id = null) {
        let url = (id) 
            ? this.baseUrl + "/api/category/categoryList/" + id 
            : this.baseUrl + "/api/category/categoryList";

        return await axios.get(url)
            .catch(function (error) { 
                console.log(error);
                return { data: {categories: [], audio: [] } }
            });
    }


    // **************************************************
    // Retrieve the audio details by id
    async getAudioPath(id) {
        let url = this.baseUrl + "/api/audio/audioPath/" + id;

        return await axios.get(url)
            .catch(function (error) { 
                console.log(error.message); 
                throw(error); 
            });  
    }


    // **************************************************
    // Retrieve parent category name
    async getCategoryName(id) {
        var retData = await axios.get(this.baseUrl + '/api/category/name/' + id)
            .catch(function (error) { 
                console.log(error.message); 
                throw(error); 
            });

        return retData.data; 
    }
}