<script setup>
    import { onBeforeMount, ref, inject, watch } from 'vue';
    import { apiBaseUrl } from "@/config/config";
    import router from '@/router';


    // **** VARIABLES ****
    const categories = ref([]);                     // List of category
    const audios = ref([]);                         // List of audio
    const circularLoader = ref(false);              // Show / Hide circular spinner
    const apiService = inject('apiService');        // Api service
    const parentCat = ref("");                      // Parent category name


    // **************************************************
    // Registers a hook to be called right before the component is to be mounted
    onBeforeMount(async () => { await treePopulation(); });
    watch(
        () => router.currentRoute.value,
        async () => { await treePopulation(); }
    )


    // **************************************************
    async function treePopulation() {
        circularLoader.value = true;                // Show circular loader
        
        // Retrieve category name
        var parentId = router.currentRoute.value.params.id;
        if (parentId && parentId != undefined) {
            parentCat.value = await apiService.getCategoryName(parentId);
        }        
        
        var retVal = await apiService.getList(!router.currentRoute.value.params.id ? null : router.currentRoute.value.params.id);
        manageApiData(retVal.data);             // Api get-data management
        circularLoader.value = false;           // Hide circular loader
    }


    // **************************************************
    // Management of clicks on a category / audio and routing
    function categoryItemClick(id) {
        if (router.currentRoute.value.params.id != id) {
            circularLoader.value = true;            // Show circular loader
            router.push({path: '/category/' + id}); // Page routing
        }
    }
    function categoryAudioClick(id) {
        router.push({path: '/podcast/player/' + id});
    }
    function back() {
        circularLoader.value = true;                // Show circular loader
        router.go(-1); 
    }


    // **************************************************
    // Manage data from api
    function manageApiData(retVal) {
        // Reset ref variables
        categories.value = [];
        audios.value = [];

        // List population
        if (retVal.categories)
            retVal.categories.forEach(item => { categories.value.push(item); });
        if (retVal.audio)
            retVal.audio.forEach(item => { audios.value.push(item); });
    }
</script>

<template>
    <!-- Appbar -->
    <nav class="navbar appbar">
        <div class="container-fluid">
            <a class="navbar-brand appbar-title">
                <img src="@/assets/logo.png" alt="" width="30" height="30" class="d-inline-block align-text-top">
                ROTARY PODCAST
            </a>
        </div>
    </nav>

    <!-- Tree -->
    <div v-if="!circularLoader">
        <!-- Back button -->
        <button type="button" class="btn btn-outline-dark btn" @click="back();" v-if="router.currentRoute.value.fullPath != '/'">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-90deg-up" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4.854 1.146a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L4 2.707V12.5A2.5 2.5 0 0 0 6.5 15h8a.5.5 0 0 0 0-1h-8A1.5 1.5 0 0 1 5 12.5V2.707l3.146 3.147a.5.5 0 1 0 .708-.708l-4-4z"/>
            </svg>
            Back: <span style="font-weight: bold;">{{ parentCat }}</span>
        </button>
        <div class="row-flex">
            <!-- Categories -->
            <div v-if="categories.length > 0" class="main-div">
                <h1>CATEGORIE</h1>
                <ul class="list-group">
                    <div v-for="cat in categories" :key="cat.id" class="item-spacing">
                        <a class="list-group-item list-group-item-action item" @click="categoryItemClick(cat.id)">
                            <div class="item">
                                
                                <!-- Icon management -->
                                <template v-if="cat.icon">
                                    <img v-bind:src="apiBaseUrl + '/' + cat.icon" height="22" width="22">
                                </template>
                                <template v-else>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-folder" viewBox="0 0 16 16">
                                        <path d="M.54 3.87.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.826a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31zM2.19 4a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4H2.19zm4.69-1.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707z"/>
                                    </svg>
                                </template>
            
                                <div style="width: 10px;"></div>
            
                                {{ cat.descr }}
                            </div>
                        </a>
                    </div>
                </ul>
            </div>
            <!-- Audio -->
            <div v-if="audios.length > 0" class="main-div">
                <h1>AUDIO</h1>
                <ul class="list-group">
                    <div v-for="a in audios" :key="a.id" class="item-spacing">
                        <a class="list-group-item list-group-item-action item" @click="categoryAudioClick(a.id);">
                            <div class="item">
                                
                                <!-- Icon management -->
                                <template v-if="a.icon">
                                    <img v-bind:src="apiBaseUrl + '/' + a.icon" height="22" width="22">
                                </template>
                                <template v-else>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-file-earmark-music" viewBox="0 0 16 16">
                                        <path d="M11 6.64a1 1 0 0 0-1.243-.97l-1 .25A1 1 0 0 0 8 6.89v4.306A2.572 2.572 0 0 0 7 11c-.5 0-.974.134-1.338.377-.36.24-.662.628-.662 1.123s.301.883.662 1.123c.364.243.839.377 1.338.377.5 0 .974-.134 1.338-.377.36-.24.662-.628.662-1.123V8.89l2-.5V6.64z"/>
                                        <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                                    </svg>
                                </template>
            
                                <div style="width: 10px;"></div>
            
                                {{ a.descr }}
                            </div>
                        </a>
                    </div>
                </ul>
            </div>
            <!-- Empty list -->
            <div v-if="categories.length == 0 && audios.length == 0" class="main-div">
                <p>Nessun dato disponibile</p>
            </div>
        </div>
    </div>
    <div class="spinner-border center" role="status" v-else>
        <span class="visually-hidden">Loading...</span>
    </div>


</template>

<style scoped>
    .appbar { background-color: #03a9f4;  /* Lightblue like flutter */ }
    .item:hover { cursor: pointer; }
    .btn { 
        margin-left: 30px;
        margin-top: 30px;
    }

    .appbar-title {
        font-weight: bold;
        color: white;
    }

    .item {
        display: flex;
        flex-direction: row;
    }

    .main-div {
        margin: 30px 30px 30px 30px;
        flex: 1
    }

    .center {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #03a9f4;;
    }

    .row-flex {
        display: flex;
        flex-direction: row;
    }

    .item-spacing { margin-bottom: 20px; }
</style>