import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../pages/HomePage.vue";
import PodcastPlayer from "../pages/PodcastPlayer.vue";

const router = createRouter({
	history: createWebHistory(''),
	routes: [
        {
            path: "/",
            name: "HomePage",
            component: HomePage,
        },
        {
            path: "/category/:id",
            name: "SubCategory",
            component: HomePage,
        },
        {
            path: "/podcast/player/:id",
            name: "PodcastPlayer",
            component: PodcastPlayer,
        },

		// **** Not found route interceptor ****
        { path: '/:pathMatch(.*)*', redirect: '/' }
	]
});

export default router;